import { ReactElement, useEffect, useState } from 'react'

import { formatCurrency } from 'utils/formatters'
import { NetworkLabel } from 'components/ui/network-label'

import SearchIcon from 'images/icon-search.svg'

import { ICurrency } from 'store/fetchers/currencies'

import cn from 'classnames'

import { countDecimalPlaces } from '../../../../../utils/count-decimal-places'

import { floorNumber } from '../../../../../utils/floor-number'

import IconCheck from './icon-check.svg'
import styles from './styles.module.scss'

export const CoinList = ({
  list,
  selected,
  onChoose,
  showBalance = false,
  isStableFirst = false,
  listFullWidth = false,
  isExchangeLayout = false,
  isVip = false,
  isVisibleList = false,
}) => {
  const handleChoose = (item: ICurrency) => () => {
    onChoose(item)
  }

  const [items, setItems] = useState<ReactElement[]>([])
  const [stableItems, setStableItems] = useState<ReactElement[]>([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    const newItems: ReactElement[] = []
    const newStableItems: ReactElement[] = []

    for (const currency of list) {
      const code = currency.code

      if (currency.name.toLowerCase().includes(searchValue) || code.toLowerCase().includes(searchValue)) {
        const isSelected = currency.code === selected?.code && currency.network === selected?.network
        const item = (
          <div
            className={cn(styles.coinsListItem, { [styles.vip]: isVip })}
            key={`${code}-${currency.network}`}
            onClick={handleChoose(currency)}
          >
            <img alt={currency.code} width={20} height={20} src={currency.logo_url} />
            <div className={styles.ticker}>{formatCurrency(currency, true)}</div>
            <NetworkLabel currency={currency} truncate={true} />
            <div className={styles.coinName}>
              {showBalance &&
              currency?.balance &&
              floorNumber(+Number(currency?.balance).toFixed(countDecimalPlaces(currency?.balance)), 6) > 0
                ? floorNumber(+Number(currency?.balance).toFixed(countDecimalPlaces(currency?.balance)), 6)
                : currency.name}
            </div>
            {isSelected && <img src={IconCheck} alt="CheckedCoin" className={styles.checkIcon} />}
          </div>
        )

        if (currency.is_stable) {
          newStableItems.push(item)
        } else {
          newItems.push(item)
        }
      }
    }

    setItems(newItems)
    setStableItems(newStableItems)
  }, [searchValue, list, showBalance, selected])

  function handleInput(e) {
    setSearchValue(e.target.value.toLowerCase())
  }

  return (
    <div
      className={cn(
        styles.coinsList,
        { [styles.coinsList_exchange]: isExchangeLayout },
        { [styles.vip]: isVip },
        { [styles.coinsList_opened]: isVisibleList },
      )}
      style={{ maxWidth: listFullWidth ? 'unset' : '310px' }}
    >
      <div className={cn(styles.search, { [styles.vip]: isVip })}>
        <img src={SearchIcon} className={styles.searchIcon} alt="IconInputArrow" width={16} height={16} />
        <input autoFocus onInput={handleInput} className={styles.searchInput} type="text" placeholder="Search Crypto" />
      </div>
      <div className={cn(styles.coinsListScrollbar, { [styles.vip]: isVip })}>
        {isStableFirst && stableItems.length > 0 && (
          <>
            <p className={cn(styles.searchGroup, { [styles.vip]: isVip })}>Stablecoins</p>
            {stableItems}
          </>
        )}

        {items.length > 0 && (
          <>
            <p className={cn(styles.searchGroup, { [styles.vip]: isVip })}>Crypto Assets</p>
            {items}
          </>
        )}

        {!isStableFirst && stableItems.length > 0 && (
          <>
            <p className={cn(styles.searchGroup, { [styles.vip]: isVip })}>Stablecoins</p>
            {stableItems}
          </>
        )}
      </div>
    </div>
  )
}
